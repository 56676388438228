<template>
  <div>
    <!-- 匯入網域 -->
    <domain-list-add-modal
      ref="domainAddModal"
      @refetch-data="refetchData"
    />

    <!-- 網域編輯 -->
    <domain-list-edit-modal
      ref="domainEditModal"
      @refetch-data="refetchData"
    />

    <b-tabs
      v-model="tabIndex"
      pills
      lazy
    >
      <b-tab title="網域服務">
        <DomainListService
          ref="DomainListService"
          @submit-update="onSubmitUpdate"
        />
      </b-tab>

      <b-tab title="服務紀錄">
        <DomainListAction
          ref="DomainListAction"
          :tab-index="1"
          @submit-update="onSubmitUpdate"
        />
      </b-tab>

      <b-tab title="關閉紀錄">
        <DomainListAction
          ref="DomainListClose"
          :tab-index="2"
          @submit-update="onSubmitUpdate"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// API
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import moment from 'moment'
import { useRouter } from '@core/utils/utils'

// UI
import { BTabs, BTab } from 'bootstrap-vue'

// import router from '@/router'
import store from '@/store'

// Component
import useStoreModule from '../useStoreModule'
import DomainListService from './DomainListService.vue'
import DomainListAction from './DomainListAction.vue'
import DomainListAddModal from './DomainListAddModal.vue'
import DomainListEditModal from './DomainListEditModal.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BTabs,
    BTab,

    DomainListService,
    DomainListAction,
    DomainListAddModal,
    DomainListEditModal,
  },
  data() {
    return {
      title: this.router.currentRoute.meta.pageTitle,
    }
  },
  methods: {
    moment,
    // (刷新列表)
    refetchData() {
      if (this.tabIndex === 0) this.$refs.DomainListService.refetchTable()
      if (this.tabIndex === 1) this.$refs.DomainListAction.refetchTable()
      if (this.tabIndex === 2) this.$refs.DomainListClose.refetchTable()
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        this.$refs.domainAddModal.getData()
        return
      }
      setTimeout(() => { this.$refs.domainEditModal.getData(item) }, 200)
    },
  },
  setup() {
    // 註冊模組
    const DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME = 'admin-service-domain'

    if (!store.hasModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)
    })

    const tabIndex = ref(0)
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    const actionMap = { list: 0, open: 1, close: 2 }

    tabIndex.value = actionMap[routeParams.value.action] ?? 0

    watch(tabIndex, newIndex => {
      const action = Object.keys(actionMap).find(key => actionMap[key] === newIndex)
      if (action) router.push({ name: 'admin-service-domain', params: { action } })
    })

    return {
      tabIndex,
      router,
      routeParams,
    }
  },
}
</script>
