import { render, staticRenderFns } from "./remarkModal.vue?vue&type=template&id=37c5b35e&scoped=true&"
import script from "./remarkModal.vue?vue&type=script&lang=js&"
export * from "./remarkModal.vue?vue&type=script&lang=js&"
import style0 from "./remarkModal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./remarkModal.vue?vue&type=style&index=1&id=37c5b35e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c5b35e",
  null
  
)

export default component.exports