import { ref, watch } from '@vue/composition-api'
// import { $themeColors } from '@themeConfig'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
import router from '@/router'

export const useDomainSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    stateType: [{
      0: '購買中', 1: '啟用中', 2: '購買失敗', 3: '已退款', 4: '逾期', 5: '關閉', 6: '待處理',
    }, {
      0: 'light-secondary', 1: 'light-success', 2: 'light-danger', 3: 'light-primary', 4: 'light-warning', 5: 'light-secondary', 6: 'light-info',
    }],
    actionType: [{
      0: '免費', 1: '購買託管', 2: '攜入', 3: '續約', 4: '匯入',
    }, {
      0: 'light-secondary', 1: 'light-primary', 2: 'light-warning', 3: 'light-success', 4: 'light-info',
    }],
    tabOptions: {
      0: '服務網域', 1: '服務紀錄', 2: '關閉紀錄',
    },
  }

  const domainActionOptions = [
    { label: '網域購買託管', value: 1 },
    { label: '網域攜入', value: 2 },
    { label: '網域續約', value: 3 },
    { label: '免費網址', value: 0 },
  ]

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    domainActionOptions,
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useDomainServiceList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '網域', key: 'url', sortable: true, searchable: true, select: true,
    },
    {
      label: '姓名', key: 'customer', sortable: true, searchable: true, select: true,
    },
    {
      label: '約期 (年)', key: 'quantity', sortable: true, searchable: true, select: true,
    },
    {
      label: '開始時間', key: 'startTime', sortable: true, searchable: true, select: true,
    },
    {
      label: '到期時間', key: 'endTime', sortable: true, searchable: true, select: true,
    },
    {
      label: '經銷商', key: 'dealer', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchAction = ref(null)

  const blankServiceDomainData = {
    id: null,
    ssid: null,
    suffix: null,
    url: null,
    state: 0,
    start_time: null,
    end_time: null,
    system_order_id: null,
    action: null,
    content: '',
    customer: null,
    prefix: null,
    quantity: 1,
    transKey: null,
    domain_name_dealer_id: null,
    domain_name_agent_id: null,
    remark_display: false,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  }

  const blankDealerInfoData = {
    id: null,
    name: null,
  }

  const blankDomainAgent = {
    id: null,
    agent: null,
  }

  const blankCustomerData = {
    id: null,
    image: null,
    account: null,
    name: null,
    family_name: null,
    branch_id: null,
  }

  const setDomainServiceAdd = (...arg) => store.dispatch('admin-service-domain/setDomainServiceAdd', ...arg)
  const setDomainServiceUpdate = (...arg) => store.dispatch('admin-service-domain/setDomainServiceUpdate', ...arg)

  const getDomainListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchAction.value !== null) {
      resolveFilters = `action: ${searchAction.value}`
    }

    // if (searchDealerId.value) {
    //   if (resolveFilters) {
    //     resolveFilters = `${resolveFilters};domain_name_dealer_id: ${searchDealerId.value}`
    //   } else resolveFilters = `domain_name_dealer_id: ${searchDealerId.value}`
    // }

    const queryParams = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    }

    store.dispatch('admin-service-domain/getDomainService', queryParams)
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankServiceDomainData, item),
            domain_name_dealer_info: item.domain_name_dealer_id && item.domain_name_dealer_info ? syncObject(blankDealerInfoData, item.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
            customer_info: item.customer && item.customer_info ? syncObject(blankCustomerData, item.customer_info) : syncObject(blankCustomerData, {}),
            domain_name_agent_info: item.domain_name_agent_id && item.domain_name_agent_info ? syncObject(blankDomainAgent, item.domain_name_agent_info) : syncObject(blankDomainAgent, {}),
            order_info: item.system_order_id && item.order ? syncObject({ id: null }, item.order) : syncObject({ id: null }, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchAction], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchAction,
    timeRange,
    blankServiceDomainData,
    blankDealerInfoData,
    blankDomainAgent,
    blankCustomerData,
    refetchData,
    refetchTable,
    getDomainListData,
    setDomainServiceAdd,
    setDomainServiceUpdate,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useDomainActionList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankServiceDomainData,
    blankDealerInfoData,
    blankDomainAgent,
    blankCustomerData,
  } = useDomainServiceList()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '類型', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '網域', key: 'url', sortable: true, searchable: true, select: true,
    },
    // {
    //   label: '備註', key: 'content', sortable: true, searchable: true, select: true,
    // },
    {
      label: '姓名', key: 'customer', sortable: true, searchable: true, select: true,
    },
    {
      label: '約期 (年)', key: 'quantity', sortable: true, searchable: true, select: true,
    },
    {
      label: '開始時間', key: 'startTime', sortable: true, searchable: true, select: true,
    },
    {
      label: '到期時間', key: 'endTime', sortable: true, searchable: true, select: true,
    },
    // {
    //   label: '訂單', key: 'order', sortable: true, searchable: true, select: true,
    // },
    {
      label: '經銷商', key: 'dealer', sortable: true, searchable: true, select: false,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchAction = ref(null)
  const searchService = ref(1)
  // const isLoadingBusy = ref(false)

  const getDomainListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchAction.value !== null) {
      resolveFilters = `action: ${searchAction.value}`
    }

    // if (searchDealerId.value) {
    //   if (resolveFilters) {
    //     resolveFilters = `${resolveFilters};domain_name_dealer_id: ${searchDealerId.value}`
    //   } else resolveFilters = `domain_name_dealer_id: ${searchDealerId.value}`
    // }

    const queryParams = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    }
    if (searchService.value === 2) queryParams.onlyTrashed = true

    store.dispatch('admin-service-domain/getDomainList', queryParams)
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankServiceDomainData, item),
            domain_name_dealer_info: item.domain_name_dealer_id && item.domain_name_dealer_info ? syncObject(blankDealerInfoData, item.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
            customer_info: item.customer && item.customer_info ? syncObject(blankCustomerData, item.customer_info) : syncObject(blankCustomerData, {}),
            domain_name_agent_info: item.domain_name_agent_id && item.domain_name_agent_info ? syncObject(blankDomainAgent, item.domain_name_agent_info) : syncObject(blankDomainAgent, {}),
            order_info: item.system_order_id && item.order ? syncObject({ id: null, state: 0 }, item.order) : syncObject({ id: null, state: 0 }, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchAction], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchService,
    searchAction,
    timeRange,
    blankServiceDomainData,
    blankDealerInfoData,
    refetchData,
    refetchTable,

    getDomainListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useDomainServiceView = () => {
  const {
    // useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankServiceDomainData,
    blankDealerInfoData,
    blankDomainAgent,
    blankCustomerData,
  } = useDomainServiceList()

  const isServiceBusy = ref(false)
  const setOrderServiceDomain = (...arg) => store.dispatch('admin-service-domain/setOrderServiceDomain', ...arg)

  const getDomainInfoData = (url, callback) => {
    if (!url) {
      router.push({ name: 'admin-service-domain' })
      return
    }

    // 整理filters
    const resolveFilters = `url: ${url}`

    store.dispatch('admin-service-domain/getDomainList', {
      filters: resolveFilters,
      noPagination: true,
      withTrashed: true,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        if (total <= 0) {
          router.push({ name: 'admin-service-domain' })
          return
        }

        // (搜尋是否有啟用中的服務)
        const activatingService = data.find(item => (item.state === 1 || item.state === 4 || item.state === 5 || item.state === 6))

        // (是否有啟用中的服務-否)
        if (!activatingService) {
          const firstService = data[0]
          const resolveFailData = {
            ...syncObject(blankServiceDomainData, firstService),
            domain_name_dealer_info: firstService.domain_name_dealer_id && firstService.domain_name_dealer_info ? syncObject(blankDealerInfoData, firstService.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
            customer_info: firstService.customer && firstService.customer_info ? syncObject(blankCustomerData, firstService.customer_info) : syncObject(blankCustomerData, {}),
            domain_name_agent_info: firstService.domain_name_agent_id && firstService.domain_name_agent_info ? syncObject(blankDomainAgent, firstService.domain_name_agent_info) : syncObject(blankDomainAgent, {}),
            // order_info: firstService.system_order_id && firstService.order ? syncObject({ id: null, state: 0 }, firstService.order) : syncObject({ id: null, state: 0 }, {}),
          }
          resolveFailData.id = null
          resolveFailData.errorId = firstService.id

          store.commit('admin-service-domain/UPDATE_DOMAIN_INFO_STATE', resolveFailData)

          callback()
          return
        }

        // (是否有啟用中的服務-是)
        const resolveActivatingData = {
          ...syncObject(blankServiceDomainData, activatingService),
          domain_name_dealer_info: activatingService.domain_name_dealer_id && activatingService.domain_name_dealer_info ? syncObject(blankDealerInfoData, activatingService.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
          customer_info: activatingService.customer && activatingService.customer_info ? syncObject(blankCustomerData, activatingService.customer_info) : syncObject(blankCustomerData, {}),
          domain_name_agent_info: activatingService.domain_name_agent_id && activatingService.domain_name_agent_info ? syncObject(blankDomainAgent, activatingService.domain_name_agent_info) : syncObject(blankDomainAgent, {}),
          order_info: activatingService.system_order_id && activatingService.order ? syncObject({ id: null, state: 0 }, activatingService.order) : syncObject({ id: null, state: 0 }, {}),
        }

        let quantityLock = 0
        let quantityTotal = 0
        let startTime = resolveActivatingData.start_time ? new Date(resolveActivatingData.start_time) : new Date()
        let endTime = resolveActivatingData.end_time ? new Date(resolveActivatingData.end_time) : new Date()

        data.forEach((item, index) => {
          if (item.customer !== resolveActivatingData.customer) quantityLock = 1
          if (quantityLock === 0 && (item.state === 1 || item.state === 5 || item.state === 6)) {
            quantityTotal += item.quantity
            // (啟用服務時間)
            if (item.start_time && new Date(startTime) > new Date(item.start_time)) {
              startTime = item.start_time
            }

            // (結束服務時間)
            if (item.end_time && new Date(endTime) < new Date(item.end_time)) {
              endTime = item.end_time
            }
          }

          // (最新更新時間)
          if (new Date(item.updated_at) > new Date(resolveActivatingData.updated_at)) {
            resolveActivatingData.updated_at = item.updated_at
          }

          // (最早創建時間)
          if (index === data.length - 1) resolveActivatingData.created_at = item.created_at
        })

        resolveActivatingData.quantityTotal = quantityTotal
        resolveActivatingData.start_time = startTime
        resolveActivatingData.end_time = endTime

        store.commit('admin-service-domain/UPDATE_DOMAIN_INFO_STATE', resolveActivatingData)
        callback()
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const checkDomainInfoState = callback => {
    // 整理filters
    const url = router.currentRoute.params.id
    const resolveFilters = `url: ${url}`

    store.dispatch('admin-service-domain/getDomainList', {
      filters: resolveFilters,
      noPagination: true,
      withTrashed: true,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        if (total <= 0) {
          // router.push({ name: 'admin-service-domain' })
          callback(false)
          return
        }

        // (搜尋是否有啟用中的服務)
        const activatingService = data.find(item => item.state === 1)

        // (是否有啟用中的服務-否)
        if (!activatingService) {
          const firstService = data[0]
          if (firstService.state !== 0) {
            callback(firstService)
            return
          }
          callback(firstService)
          return
        }

        callback(activatingService)
      })
      .catch(() => callback(null))
  }

  return {
    isServiceBusy,
    setOrderServiceDomain,
    checkDomainInfoState,
    getDomainInfoData,
  }
}

export const useDomainServiceInfoList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    searchQuery,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankServiceDomainData,
    blankDealerInfoData,
    blankDomainAgent,
    blankCustomerData,
  } = useDomainServiceList()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '類型', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '訂單', key: 'order', sortable: true, searchable: true, select: true,
    },
    // {
    //   label: '備註', key: 'content', sortable: true, searchable: true, select: true,
    // },
    // {
    //   label: '網域', key: 'url', sortable: true, searchable: true, select: true,
    // },
    {
      label: '約期 (年)', key: 'quantity', sortable: true, searchable: true, select: false,
    },
    {
      label: '開始時間', key: 'startTime', sortable: true, searchable: true, select: false,
    },
    {
      label: '到期時間', key: 'endTime', sortable: true, searchable: true, select: false,
    },
    {
      label: '姓名', key: 'customer', sortable: true, searchable: true, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '經銷商', key: 'dealer', sortable: true, searchable: true, select: false,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', sortable: false, searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchAction = ref(null)

  const getDomainListData = (ctx, callback) => {
    // 整理filters
    const url = router.currentRoute.params.id
    let resolveFilters = `url: ${url}`

    if (searchAction.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};action: ${searchAction.value}`
      } else resolveFilters = `action: ${searchAction.value}`
    }

    const queryParams = {
      search: searchQuery.value,
      filters: resolveFilters,
      noPagination: true,
      withTrashed: true,
      _: Date.now(),
    }

    store.dispatch('admin-service-domain/getDomainList', queryParams)
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankServiceDomainData, item),
            domain_name_dealer_info: item.domain_name_dealer_id && item.domain_name_dealer_info ? syncObject(blankDealerInfoData, item.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
            customer_info: item.customer && item.customer_info ? syncObject(blankCustomerData, item.customer_info) : syncObject(blankCustomerData, {}),
            domain_name_agent_info: item.domain_name_agent_id && item.domain_name_agent_info ? syncObject(blankDomainAgent, item.domain_name_agent_info) : syncObject(blankDomainAgent, {}),
            order_info: item.system_order_id && item.order ? syncObject({ id: null, state: 0 }, item.order) : syncObject({ id: null, state: 0 }, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  watch([searchQuery, timeRange, searchAction], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    searchQuery,
    tableColumns,
    dataMeta,
    searchAction,
    timeRange,
    refetchData,

    getDomainListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
